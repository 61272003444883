<template>
    <div class="col-span-3 lg:col-span-2 bg-stone-300 px-2">

        <select v-model="quantity" class="py-3 md:py-4 w-[100%] bg-stone-300 ">

            <option :value="i - 1" v-for="i in 11">{{i - 1}}</option>

        </select>

    </div>
</template>

<script>
export default {
    props: {
        value: {
            default: 0
        },
    },
    data(){
        return {
            quantity: this.value
        }
    },
    methods: {
        onValueChange(e) {
            this.$emit('update:modelValue', parseInt(e.target.value));
            //console.log(e.target.value);
        },
        increase() {
            //console.log("emit input");
            this.$emit('input', this.value + 1);
        },

        decrease() {
            this.$emit('input', this.value - 1);
        }

    },
    watch: {
        quantity(){
            console.log('emit input');
            this.$emit('update:modelValue', this.quantity)
        }
    }
}
</script>

<style >
input:hover {
    border-color: #fafafa;
}

input {
    border-color: transparent;
    transition: all 0.3s;
    width: 35px;
}

.quantity-picker-root {
    overflow: hidden;
    background-color: white;
    border-radius: 50px;
}

.text-app {
    color: #05121e;
}

.control {
    transition: all 0.1s;
}

.control:hover {
    background-color: #05121e9d;
    cursor: pointer;
}

/**
 * Basic CSS Media Query Template
 * TODO: I should probably use Sass...
 * Author: Michael Vieth
 * ------------------------------------------
 *  Responsive Grid Media Queries - 1280, 1024, 768, 480
 *   1280-1024   - desktop (default grid)
 *   1024-768    - tablet landscape
 *   768-480     - tablet 
 *   480-less    - phone landscape & smaller
 * --------------------------------------------
 */
 @media all and (min-width: 1281px) {
    .input {
        font-size: 24px;
    }
    .control {
        font-size: 24px;
    }
}

@media all and (min-width: 1024px) and (max-width: 1280px) {
    .input {
        font-size: 24px;
    }
    .control {
        font-size: 24px;
    }
}

@media all and (min-width: 768px) and (max-width: 1024px) {
    .input {
        font-size: 24px;
    }
    .control {
        font-size: 24px;
    }
}

@media all and (min-width: 480px) and (max-width: 768px) {
    .input {
        font-size: 24px;
    }
    .control {
        font-size: 24px;
    }
}

@media all and (max-width: 480px) {
    .input {
        font-size: 18px;
    width: 27px;
    }
    .control {
        font-size: 24px;
    }
}


@media all and (max-width: 320px) {
    .input {
        font-size: 18px;
        width: 27px;
    }
    .control {
        font-size: 14px;
    }
}

/* Portrait */
@media screen and (orientation:portrait) {
    /* Portrait styles here */
}

/* Landscape */
@media screen and (orientation:landscape) {
    /* Landscape styles here */
}


/* CSS for iPhone, iPad, and Retina Displays */

/* Non-Retina */
@media screen and (-webkit-max-device-pixel-ratio: 1) {}

/* Retina */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (-o-min-device-pixel-ratio: 3/2),
only screen and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-device-pixel-ratio: 1.5) {}

/* iPhone Portrait */
@media screen and (max-device-width: 480px) and (orientation:portrait) {}

/* iPhone Landscape */
@media screen and (max-device-width: 480px) and (orientation:landscape) {}

/* iPad Portrait */
@media screen and (min-device-width: 481px) and (orientation:portrait) {}

/* iPad Landscape */
@media screen and (min-device-width: 481px) and (orientation:landscape) {

}
</style>