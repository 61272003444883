<template>
    <div>
        <qrcode-stream v-if="scanOn" @detect="onDecode"></qrcode-stream>

        <!-- <ModalsContainer /> -->

        <!-- <modal :adaptive="true" name="ticket-already-scanned">
            <div class="d-flex align-items-center justify-content-center w-100 h-100">

                <div class="text-center" style="color: #00a249">
                    <svg-icon type="mdi" size="92" :path="Icons.mdiQrcodeScan"></svg-icon>
                    <div class="fs-4 fw-bold mt-3">Code QR Scanné <svg-icon type="mdi"
                            :path="Icons.mdiCheckCircle"></svg-icon> </div>

                    <div class="text-danger mt-3">Le ticket a déja été scanné</div>

                    <div>

                        <button @click="closeModal" class="btn btn-sm mt-5 btn-light-danger">Fermer</button>

                    </div>

                </div>

            </div>
        </modal> -->

        <widget-container-modal />

    </div>
</template>

<script>
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'
import axios from 'axios';
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiQrcodeScan, mdiCheckCircle } from '@mdi/js';
import { HalfCircleSpinner } from 'epic-spinners'
import { ModalsContainer } from 'vue-final-modal'
import { openModal, pushModal, closeModal, popModal } from "jenesius-vue-modal"
import { container } from "jenesius-vue-modal";
import TicketScannedComponent from './TicketScannedComponent.vue'


export default {

    components: {
        QrcodeStream,
        QrcodeDropZone,
        QrcodeCapture,
        SvgIcon,
        HalfCircleSpinner,
        WidgetContainerModal: container

    },
    mounted() {
        // this.$modal.show('ticket-scanned')
    },
    data() {
        return {
            Icons: { mdiQrcodeScan, mdiCheckCircle },
            scanOn: true,
            isModalOpened: false,
            currentTicket: undefined,
            isLoadingInfos: false,
            ticket: undefined,
            isSubmittingTicket: false,
            confirmationCode: undefined
        }
    },
    methods: {

        async onDecode(decodedString) {
            console.log("decoded", decodedString);
            this.scanOn = false;
            let modal = await openModal(TicketScannedComponent, {
                decodedString: decodedString[0].rawValue
            });
            console.log(modal)
            modal.onclose = () => {
                this.scanOn = true
                return true; //Modal will not be closed
            }
        },
    }
}
</script>