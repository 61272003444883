<template>
    <div>
        <div class="text-3xl font-light text-center">Informations de votre compte</div>
        <hr>
    </div>
    <div class="md:grid md:grid-cols-2 mt-6 md:gap-4">
        <label class="block my-3">
            <span class="text-black text-sm font-light">Adresse Email</span>
            <div class="bg-slate-100 p-1 lg:p-3">
                <input disabled :value="user.email"
                    class="input w-full block text-base flex-grow bg-[initial] focus:border-none focus:outline-none border-none active:border-none "
                    placeholder="Email" />
            </div>
        </label>
        <label class="block my-3">
            <span class="text-black text-sm font-light">Numéro de téléphone</span>
            <div class="bg-slate-100 p-1 lg:p-3">
                <input disabled :value="user.phone"
                    class="input w-full block text-base flex-grow bg-[initial] focus:border-none focus:outline-none border-none active:border-none "
                    placeholder="Numéro de téléphone" />
            </div>
        </label>
        <label class="block md:col-span-2 my-3col-span-2">
            <span class="text-black text-sm font-light">Nom & Prenoms</span>
            <div class="bg-slate-100 p-1 lg:p-3">
                <input disabled :value="user.name"
                    class="input w-full block text-base flex-grow bg-[initial] focus:border-none focus:outline-none border-none active:border-none "
                    placeholder="Email ou numéro de téléphone" />
            </div>
        </label>
    </div>
    <div class="flex justify-between mt-6">
        <button @click="$emit('goBack')" class="bg-primary hover:bg-yellow-600 transition duration-500 text-black p-3 font-semibold">Precédent</button>
        <button @click="$emit('goToNext')" class="bg-primary hover:bg-yellow-600 transition duration-500 text-black p-3 font-semibold">Suivant</button>
    </div>  
</template>

<script>
    export default {

        props: {
            user: {

            },
            onGoToNext: {

            }
        }

    }
</script>