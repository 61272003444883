<template>
    <div @closed="scanOn = true; confirmationCode = undefined" :adaptive="true" name="ticket-scanned">
        <div class="d-flex align-items-center justify-content-center w-100 h-100">

            <div class="card">

                <div class="card-body">

                    <div class="text-center" style="color: #00a249">
                <svg-icon type="mdi" size="92" :path="Icons.mdiQrcodeScan"></svg-icon>
                <div class="fs-4 fw-bold mt-3">Code QR Scanné <svg-icon type="mdi" :path="Icons.mdiCheckCircle"></svg-icon>
                </div>

                <div v-if="isLoadingInfos" class="d-flex flex-column align-items-center justify-content-center py-3">
                    <half-circle-spinner :animation-duration="1000" :size="30" color="#00a249" />
                    Obention des informations...
                </div>

                <div v-if="(ticket && ticket.ticket && !ticket.ticket.scan_date)">

                    <div class="my-3">
                        <input v-model="confirmationCode" type="text" placeholder="Code de confirmation"
                            class="text-center form-control form-control-solid">
                    </div>

                    <button @click="closeModal" class="btn btn-sm mt-5 btn-light-danger">Annuler</button>
                    <button :disabled="isSubmittingTicket" @click="confirmTicket"
                        class="btn btn-sm mt-5 btn-light-success">Valider le ticket <half-circle-spinner
                            v-if="isSubmittingTicket" class="d-inline-block" :animation-duration="1000" :size="15"
                            color="#00a249" /></button>

                </div>

                <div v-if="ticket && ticket.ticket && ticket.ticket.scan_date">

                    <div class="text-danger mt-3">Le ticket a déja été scanné</div>

                    <div>

                        <button @click="closeModal" class="btn btn-sm mt-5 btn-light-danger">Fermer</button>

                    </div>
                </div>

            </div>
                </div>

            </div>


        </div>
    </div>
</template>

<script>


import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'
import axios from 'axios';
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiQrcodeScan, mdiCheckCircle } from '@mdi/js';
import { HalfCircleSpinner } from 'epic-spinners'
import { ModalsContainer } from 'vue-final-modal'
import { openModal, pushModal, closeModal, popModal } from "jenesius-vue-modal"
import { container } from "jenesius-vue-modal";



export default {
    props: {
        decodedString: {}
    },
    components: {SvgIcon, HalfCircleSpinner},
    data(){
        return {
            Icons: { mdiQrcodeScan, mdiCheckCircle },
            scanOn: true,
            isModalOpened: false,
            currentTicket: undefined,
            isLoadingInfos: false,
            ticket: undefined,
            isSubmittingTicket: false,
            confirmationCode: undefined
        }
    },
    mounted(){
        this.onDecode(this.decodedString)
    },
    methods: {
        async getTicket(code) {
            const ticket = (await axios.get(`${BASE_URL}/api/ticket-scan/get-by-code/${code}`, {
                params: {
                    auth: document.querySelector('meta[name="auth"]').content
                }
            })).data

            return ticket;
        },

        async onDecode(decodedString) {
            this.scanOn = false;

            try {

                this.isLoadingInfos = true;
                const ticket = await this.getTicket(decodedString);
                //console.log("Ticket", ticket);
                this.isLoadingInfos = false;
                if (!ticket) {
                    alert("Ticket non reconnu");
                    this.scanOn = true;
                    return;
                }

                this.ticket = ticket;


            } catch (e) {
                alert("Une erreur est survenue, Veuillez contacter un admnistrateur");
                //console.log(e)
                this.scanOn = true;
                this.ticket = undefined
                this.isLoadingInfos = false
            }

        },
        async confirmTicket() {
            try {
                this.isSubmittingTicket = true;
                const response = (await axios.get(this.ticket.confirm_url, {
                    params: {
                        code: this.confirmationCode
                    }
                }));
                alert("Ticket confirmé avec succès");
                closeModal();
            } catch (e) {
                //console.log(e.response)
                if (!e.response) {
                    alert("Une erreur c'est produite lors de la confirmation, réessayez plus tard")
                    closeModal()
                }
                else if (e.response.data.code == 'invalid-code'){
                    alert("Code incorrect");
                    closeModal();
                }
            } finally {
                this.isSubmittingTicket = false;
            }

        },

        // closeModal() {
        //     //console.log("close modal");
        //     this.$modal.hide('ticket-already-scanned')
        //     this.$modal.hide('ticket-scanned')

        //     this.scanOn = true;
        //     this.ticket = undefined
        //     this.confirmationCode = undefined

        // },
    }
}
</script>