<template>
      <step-progress active-color="#05121e" passive-color="white" :steps="totalSteps" :current-step="currentStep" icon-class="fa fa-check"></step-progress>
</template>

<script>
import StepProgress from 'vue-step-progress';
import 'vue-step-progress/dist/main.css';

export default {
    components: { StepProgress },
    props: {
        currentStep: {},
        totalSteps: {}
    }
}
</script>

<style>

.step-progress__wrapper {
    margin: 0 auto;
    position: relative;
    width: 90%
}

.step-progress__wrapper-before {
    background-color: gray;
    transform: translateY(-50%) perspective(1000px)
}

.step-progress__wrapper-after,
.step-progress__wrapper-before {
    content: "";
    height: 12px;
    left: 0;
    position: absolute;
    top: 50%;
    width: 100%
}

.step-progress__wrapper-after {
    background-color: #05121e;
    transform: scaleX(0) translateY(-50%) perspective(1000px);
    transform-origin: left center;
    transition: transform .5s ease
}

.step-progress__bar {
    align-items: center;
    display: flex;
    height: 100px;
    justify-content: space-between;
    margin-bottom: 40px;
    width: 100%
}

.step-progress__step {
    --activeColor: #05121e;
    --passiveColor: gray;
    --activeBorder: 5px;
    --passiveBorder: 5px;
    position: relative;
    z-index: 2
}

.step-progress__step span {
    color: #efefeb;
    display: block;
    font-size: 50px;
    font-weight: 900;
    opacity: 1;
    text-align: center;
    transform: translateZ(0) scale(1) perspective(1000px);
    transition: .3s ease;
    font-size: 12px;
}

@media(max-width:767px) {
    .step-progress__step span {
        font-size: 28px
    }
}

.step-progress__step--active .step-progress__step-label,
.step-progress__step--active span {
    color: var(--activeColor)
}

.step-progress__step--active .step-progress__step-icon {
    opacity: 1
}

.step-progress__step--valid .step-progress__step-icon {
    opacity: 1;
    transform: translate3d(-50%, -50%, 0) scale(1) perspective(1000px)
}

.step-progress__step--valid span {
    color: var(--activeColor);
    opacity: 0;
    transform: translateZ(0) scale(2) perspective(1000px)
}

.step-progress__step--valid .step-progress__step-label {
    color: var(--activeColor)
}

.step-progress__step:after {
    background-color: #fff;
    border: var(--passiveBorder) solid var(--passiveColor);
    border-radius: 50%;
    content: "";
    height: 75px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) perspective(1000px);
    transition: .3s ease;
    width: 75px;
    z-index: -1
}

@media(max-width:767px) {
    .step-progress__step:after {
        height: 40px;
        width: 40px
    }
}

.step-progress__step--active:after,
.step-progress__step--valid:after {
    border: var(--activeBorder) solid var(--activeColor);
    background-color: #efefeb;

}

.step-progress__step--valid:after {
    background-color: var(--activeColor)
}

.step-progress__step-label {
    color: gray;
    font-size: 18px;
    font-weight: 600;
    left: 50%;
    position: absolute;
    top: calc(-0% - 25px);
    transform: translateX(-50%) perspective(1000px);
    transition: .3s ease;
    white-space: nowrap
}

.step-progress__step-icon {
    color: #fff;
    font-size: 36px;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0) scale(0) perspective(1000px);
    transition: transform .3s ease
}

@media(max-width:767px) {
    .step-progress__step-icon {
        font-size: 22px
    }
}

/*# sourceMappingURL=main.css.map*/

</style>