/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';
import Alpine from 'alpinejs'



window.randomString = function randomString(length = 10) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.withCredentials = true;


window.Alpine = Alpine

Alpine.data(
  "searchEvents", () => ({
    results: [],
    checkeds: [],
    isEventSelected(eventId){
      return this.checkeds.find(item => item.id == eventId);
    },
    async search(input) {
      if (input.length > 3) {
        const result = await axios.get("/api/events/search", {
          params: {
            search: input
          }
        });

        this.results = result.data;
      } else {
        this.results = []
      }
    },
    toggle(eventId){
      if(this.checkeds.filter(item => item.id == eventId).length > 0){
        this.checkeds = this.checkeds.filter(item => item.id != eventId);
      } else {
        let item = this.results.find(item => item.id == eventId);
        console.log("finded item", item);
        this.checkeds.push(item);
      }
    }
  })
);

Alpine.start()

window.formatNumber = function(value) {
  if (!value) return value;
  value = parseInt(value);
  value = value.toString()
  const d = value.replace(/\B(?=(\d{3})+(?!\d))/g, " ")

  return `${d}`
}


/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// import Pusher from 'pusher-js';
// window.Pusher = Pusher;

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: import.meta.env.VITE_PUSHER_APP_KEY,
//     cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER ?? 'mt1',
//     wsHost: import.meta.env.VITE_PUSHER_HOST ? import.meta.env.VITE_PUSHER_HOST : `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
//     wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
//     wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
//     forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
//     enabledTransports: ['ws', 'wss'],
// });
