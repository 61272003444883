import './bootstrap';
// import {createApp} from 'vue'
import EventCommandComponent from './events/EventCommandComponent.vue'
import TicketBuySearchComponent from './events/TicketBuySearchComponent.vue'
import TicketScanner from './events/TicketScanner.vue'
import '../css/app.css'
import { createApp } from 'vue/dist/vue.esm-bundler';
import { i18nVue } from 'laravel-vue-i18n'
import Autocomplete from '@trevoreyre/autocomplete-vue'
import SelectEvent from './admin/create-event/SelectEvent.vue'

window.BASE_URL = import.meta.env.VITE_APP_URL;

const app = createApp({})

app.use(i18nVue, {
    resolve: async lang => {
        const langs = import.meta.glob("../../lang/*.json");
        return await langs[`../../lang/${lang}.json`]();
    }
})

app.use(Autocomplete);


app.component('event-command-component', EventCommandComponent);
app.component('ticket-search', TicketBuySearchComponent);
app.component('ticket-scanner', TicketScanner);

app.component('select-event', SelectEvent);

app.mount('#app')
