<template>
    <div class="grid grid-cols-12 gap-4 items-center my-2">

        <div :data-is-gold="`${!!ticket.is_gold}`"
            class=" bg-black relative data-[is-gold=true]:bg-orange-400 data-[is-gold=true]:font-bold ml-lg-5 my-0 ticket-label text-lg col-span-9 lg:col-span-10 py-2 md:py-3 px-2 md:px-6 flex flex-rows items-center text-white rounded-full">

            <svg v-if="ticket.is_gold" fill="#fdd82e" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" class="mr-2 w-[24px] h-24[px] md-[32px] lg-[32px]" viewBox="0 0 380 380"
                xml:space="preserve" data-darkreader-inline-fill="" style="--darkreader-inline-fill: #846d01;"
                transform="matrix(-1, 0, 0, 1, 0, 0)">

                <g id="SVGRepo_bgCarrier" stroke-width="0" />

                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />

                <g id="SVGRepo_iconCarrier">
                    <g>
                        <g>
                            <g>
                                <path
                                    d="M148.882,194.098c-1.532,1.084-2.318,2.942-2.021,4.795c0.297,1.853,1.614,3.38,3.406,3.937l20.528,6.38l6.381,20.524 c0.236,0.778,0.665,1.468,1.219,2.022c0.717,0.723,1.665,1.217,2.715,1.381c1.853,0.293,3.714-0.489,4.79-2.021l12.413-17.55 l21.494,0.274c1.873,0.025,3.604-1.02,4.452-2.69c0.852-1.671,0.679-3.682-0.439-5.185l-12.857-17.225l6.902-20.354 c0.603-1.776,0.144-3.743-1.185-5.068c-1.323-1.327-3.285-1.785-5.062-1.18l-20.359,6.9l-17.228-12.855 c-1.498-1.121-3.511-1.293-5.184-0.443c-1.671,0.851-2.715,2.579-2.689,4.454l0.275,21.493L148.882,194.098z" />
                                <path
                                    d="M377.353,272.721c-5.351-5.356-8.304-12.479-8.304-20.052c0-7.571,2.951-14.692,8.302-20.047 c3.528-3.528,3.528-9.249,0-12.776L160.149,2.644C158.457,0.954,156.161,0,153.762,0c-2.396,0-4.691,0.954-6.383,2.645 c-5.356,5.356-12.475,8.305-20.049,8.307c-7.573,0-14.694-2.95-20.052-8.308c-3.529-3.525-9.248-3.525-12.777,0L69.768,27.383 c-3.527,3.53-3.529,9.249,0,12.778c8.162,8.162,8.162,21.445,0,29.607c-3.955,3.955-9.214,6.131-14.806,6.13 c-5.589,0-10.852-2.175-14.805-6.13c-3.526-3.528-9.248-3.527-12.775,0L2.647,94.507c-3.526,3.526-3.528,9.245,0,12.773 c5.351,5.355,8.302,12.477,8.304,20.05c-0.002,7.571-2.953,14.692-8.304,20.046C0.948,149.071,0,151.367,0,153.764 s0.948,4.693,2.647,6.389L219.85,377.354c3.528,3.525,9.243,3.525,12.771,0c5.354-5.356,12.474-8.306,20.048-8.307 c7.573,0,14.692,2.949,20.052,8.309c1.763,1.764,4.076,2.645,6.391,2.645c2.309,0,4.62-0.881,6.387-2.645l24.735-24.739 c3.527-3.528,3.525-9.248,0-12.776c-8.162-8.165-8.16-21.445,0-29.609c3.955-3.954,9.214-6.129,14.806-6.129 c5.59,0,10.852,2.177,14.805,6.132c3.526,3.527,9.248,3.525,12.775-0.003l24.734-24.736c1.698-1.692,2.648-3.991,2.646-6.386 C380,276.711,379.052,274.413,377.353,272.721z M217.812,294.166L85.834,162.186l76.354-76.354l131.98,131.979L217.812,294.166z" />
                            </g>
                        </g>
                    </g>
                </g>

            </svg>
            {{ ticket.label }} | {{ formatNumber(ticket.price) }} FCFA
            
        </div>

        <QuantityPickerComponent v-if="ticket.left > 0" v-model="quantity" />
        <div v-else class="text-[10px] w-[100%] px-1 py-2 font-bold bg-red-500 col-span-2 lg:col-span-2 text-white text-center ">SOLD OUT !</div>

    </div>
</template>

<script>
import QuantityPickerComponent from './QuantityPickerComponent.vue'
console.log("formatNumber", formatNumber);
export default {
    components: { QuantityPickerComponent },
    props: { ticket: {}, value: {} },
    methods: {formatNumber},
    mounted() {
        console.log(this.ticket);
        if (this.value)
            this.quantity = this.value
    },
    data() {
        return {
            quantity: 9,
        }
    },
    watch: {
        quantity() {
            console.log("emit ticket changed");
            this.$emit('ticket', { ticket: this.ticket, quantity: this.quantity });
        },
    }
}
</script>

<style :scoped="true">
.ticket-root {
    background-color: #05121e;
    color: white;
    border-radius: 50px;
    padding: 2px;
}

/**
 * Basic CSS Media Query Template
 * TODO: I should probably use Sass...
 * Author: Michael Vieth
 * ------------------------------------------
 *  Responsive Grid Media Queries - 1280, 1024, 768, 480
 *   1280-1024   - desktop (default grid)
 *   1024-768    - tablet landscape
 *   768-480     - tablet 
 *   480-less    - phone landscape & smaller
 * --------------------------------------------
 */
@media all and (min-width: 1281px) {
    .ticket-label {
        font-size: 24px;
    }
}

@media all and (min-width: 1024px) and (max-width: 1280px) {
    .ticket-label {
        font-size: 24px;
    }
}

@media all and (min-width: 768px) and (max-width: 1024px) {
    .ticket-label {
        font-size: 24px;
    }
}

@media all and (min-width: 480px) and (max-width: 768px) {
    .ticket-label {
        font-size: 24px;
    }
}

@media all and (max-width: 480px) {
    .ticket-label {
        font-size: 12px;
    }
}</style>