<template>
    <div>
        <!-- <autocomplete :search="search" placeholder="Search for a country" aria-label="Search for a country"></autocomplete> -->
    </div>
</template>

<script>

export default {

}

</script>