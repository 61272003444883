<template>
    <div>
        <div class="text-xl md:text-3xl font-light text-center px-2 md:px-0">Pas d'inscription nécessaire !</div>
        <div class="text-sm text-center mb-4">Nous avons juste besoin de vos contacts pour vous envoyer vos tickets</div>
        <hr>
    </div>
    <div class="grid md:grid-cols-2 mt-6 gap-4">
        <label class=" my-1">
            <span class="text-black text-sm font-light">{{ $t('app.email') }}</span>
            <div class="bg-slate-100 p-2 lg:p-3">
                <input v-model="email"
                    class="input w-full block text-base flex-grow bg-[initial] focus:border-none focus:outline-none border-none active:border-none "
                    placeholder="Email" />
            </div>
            <div v-if="errors.email" class="text-red-600 font-bold text-[12px]">{{ errors.email.map(item => $t(item)).join(', ') }}</div>
        </label>
        <label class=" my-1">
            <span class="text-black text-sm font-light">{{ $t('app.téléphone') }}</span>
            <div class="bg-slate-100 p-2 lg:p-3">
                <input v-model="phone"
                    class="input w-full block text-base flex-grow bg-[initial] focus:border-none focus:outline-none border-none active:border-none "
                    :placeholder="$t('app.téléphone')" />
            </div>
            <div v-if="errors.phone" class="text-red-600 font-bold text-[12px]">{{ errors.phone.map(item => $t(item)).join(', ') }}</div>
        </label>
        <label class="md:col-span-2 my-1">
            <span class="text-black text-sm font-light">{{ $t('app.nom_complet') }}</span>
            <div class="bg-slate-100 p-2 lg:p-3">
                <input v-model="name"
                    class="input w-full block text-base flex-grow bg-[initial] focus:border-none focus:outline-none border-none active:border-none "
                    :placeholder="$t('app.nom_complet')" />
            </div>
            <div v-if="errors.name" class="text-red-600 font-bold text-[12px]">{{ errors.name.map(item => $t(item)).join(', ') }}</div>
        </label>
    </div>
    <div class="flex flex-col md:flex-row justify-between mt-6">
        <button @click="$emit('goToLogin')"
            class="bg-primary hover:bg-yellow-600 transition duration-500 my-1 md:my-0 text-black p-3 font-semibold">{{ $t('app.ouvrir_un_compte') }}</button>
        <button @click="goWithNoUser"
            class="bg-primary hover:bg-yellow-600 transition duration-500 my-1 md:my-0 text-black p-3 font-semibold">{{ $t("app.continuer_en_tant_quinvite") }}</button>
    </div>
</template>

<script>

import { object, string } from 'yup';

let userSchema = object({
    name: string().required(('app.champ_requis')),
    email: string().required(('app.champ_requis')).matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, ('app.vous_devez_saisir_une_adresse_email_valide')),
    phone: string().required(('app.champ_requis')).matches(/^[0-9]{10}$/, ('app.vous_devez_saisir_un_numéro_de_téléphone_valide')),
});

export default {

    props: {
    },
    data() {
        return {
            name: '',
            phone: '',
            email: '',
            errors: {}
        }
    },
    methods: {
        goWithNoUser() {

            try {

                const result = userSchema.validateSync({
                    name: this.name,
                    email: this.email,
                    phone: this.phone
                }, {
                    abortEarly: false
                });

                console.log("Can go yess");

                this.$emit('goWithNoUser', {
                    ...this.$data
                });

            } catch(e){
                let errors = Object.fromEntries(e.inner.map(
                    item => ([item.path, item.errors])
                ))
                this.errors = errors
            }

        }
    }

}
</script>