<template>
    <div class="container px-5">
        <div class="bg-white px-5 py-5 w-full lg:w-2/4 mx-auto rounded-md w-fit">

            <div
                class="focus:outline-none text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">
                <p>
                    Utiliser un pass KDO
                <div class="text-sm font-light mb-5">Saisissez le code du pass KDO puis son mot de passe pour diminuer le
                    prix
                    de votre ticket</div>
                </p>
            </div>

            <div v-if="errors" class="text-red-600 font-semibold text-center">{{ errors }}</div>

            <div>
                <label class="block my-3">
                    <span class="text-black text-sm font-bold">Coupon MonPassKDO</span>
                    <div class="flex bg-slate-100 p-1 lg:p-3">
                        <input :disabled="isLoading" v-model="code"
                            class="flex-1 input font-bold w-full block text-base flex-grow bg-[initial] focus:border-none focus:outline-none border-none active:border-none "
                            placeholder="Code du pass" />
                        <svg v-if="isLoading" aria-hidden="true"
                            class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor" />
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill" />
                        </svg>
                    </div>
                </label>
            </div>
            <div>
                <label class="block my-3">
                    <span class="text-black text-sm font-bold">Password</span>
                    <div class="flex bg-slate-100 p-1 lg:p-3">
                        <input :disabled="isLoading" v-model="password"
                            class="flex-1 input w-full font-bold block text-base flex-grow bg-[initial] focus:border-none focus:outline-none border-none active:border-none "
                            placeholder="Code secret" />
                        <svg v-if="isLoading" aria-hidden="true"
                            class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor" />
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill" />
                        </svg>
                    </div>
                </label>
            </div>

            <div class="flex justify-between">

                <button :disabled="isLoading" @click="checkPasscodeExist"
                    class="bg-primary transition items-centerhover:bg-yellow-700 w-full min-w-[150px] text-mg mx-auto transition duration-500 text-white p-2 font-semibold">
                    Utiliser mon pass KDO

                </button>
                <svg v-if="isLoading" aria-hidden="true"
                    class="mx-3 w-8 h-8 mr-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor" />
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill" />
                </svg>
            </div>


        </div>
    </div>
</template>

<script>


import { object, string } from 'yup';
let schema = object({
    code: string().required('Champ requis'),
    password: string().required('Champ requis'),
});


export default {
    data() {
        return {
            isLoading: false,
            code: '',
            password: '',
            errors: undefined,

        }
    },
    methods: {
        async checkPasscodeExist() {

            let formValidated = false;
            try {

                console.log("Pass KDO");
                schema.validateSync({
                    code: this.code,
                    password: this.password,
                }, {
                    abortEarly: false
                });

                this.errors = undefined;

                formValidated = true;
                this.isLoading = true;

                const result = await axios.post('/api/pass-kdo/check', {
                    code: this.code, password: this.password
                });

                if(result.data.amount_left == 0){
                    this.errors = "Ce pass KDO n'est plus valide"
                    return;
                }

                this.$emit("pass-kdo", result.data);

            } catch (e) {
                console.log(e);
                if(formValidated == false){
                    
                    this.errors = "Vous devez saisir les deux champs"

                    return;
                } 

                if(e.response && e.response.status == 422) {
                    this.errors = "Code incorrect"
                }
            } finally {
                this.isLoading = false;
            }

        }
    }
}
</script>