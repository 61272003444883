<template>

    <div>

        <div class="d-flex flex-row">
            <input v-model="search" type="text" class="form-control" placeholder="Adresse Email, Numéro de téléphone, Code de transaction">
            <div v-if="isLoading" class="mx-3"><half-circle-spinner :animation-duration="1000" :size="30" color="#00a249" /></div>

        </div>

        <div class="card m-3">
            <div class="card-body">

                <div class="table-responsive">
                    <table class="table">

                        <thead>
                            <tr>  
                                <td>Action</td>
                                <td>Acheteur</td>
                                <td>Code de transaction</td>
                                <td>Tickets</td>
                            </tr>
                        </thead>

                        <tbody>

                            <tr v-for="buy in results">
                                <td>
                                    <div>
                                        <a :href="`/admin/ticket-buy/controlleur/${buy.id}`" class="btn btn-sm btn-dark">Ouvrir</a>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <div>{{ buy.buyer_name }} {{ buy.buyer_prenoms }}</div>
                                        <div class="text-muted">{{ buy.buyer_email }}</div>
                                        <div class="text-muted">{{ buy.buyer_phone }}</div>
                                    </div>
                                </td>
                                <td class="fw-bolder fw-bold">{{ buy.transaction_id }}</td>
                                <td class="fw-bolder fw-bold text-success">{{ buy.tickets.length }} Tickets</td>
                            </tr>
                            
                        </tbody>

                    </table>
                </div>

            </div>
        </div>

    </div>

</template>

<script>

import { HalfCircleSpinner } from 'epic-spinners'
import axios from 'axios';


    export default {
        components: {HalfCircleSpinner},
        data(){
            return {
                isLoading: false,
                search: '',
                results: [],
            }
        },
        methods: {
            async searchTickets(searchString){
                try {

                    this.results = [];
                    this.isLoading = true;
                    
                    const results = (await axios.get(`${BASE_URL}/api/ticket-search/${searchString}`)).data

                    this.results = results;

                    console.log(results);

                } catch (e){
                    console.log(e);
                } finally {
                    this.isLoading = false;
                }
            }
        },
        watch: {
            search(){
                if(this.search.length > 3){
                    this.searchTickets(this.search)
                } else {
                    this.results = [];
                }
            }
        }
    }
</script>