<template>

    <div class="flex flex-rows  justify-around">
        <div :aria-checked="currentStep >= index"  class="group" v-for="step, index in steps">
            <div class="relative">
                <div class="text-center
                 text-gray-400 group-aria-checked:text-primary top-[-40%] font-light text-sm">{{step}}</div>
                <div class="p-1 w-fit mx-auto rounded-full border-4 border-gray-300 group-aria-checked:border-primary" >
                    <div class="bg-gray-300 group-aria-checked:bg-primary rounded-full h-[48px] w-[48px] items-center justify-center flex font-bold text-xl text-white"><span>{{ index + 1 }}</span></div>
                </div>

            </div>
        </div>
    </div>

</template>

<script>
    export default {
        props: {
            steps: {
                default: () => []
            },
            currentStep: {}
        },
        mounted(){
            console.log(this.steps);
        }
    }
</script>