<template>
    <div class="my-5">
        <div class="ticket-root d-flex justify-content-between align-items-center my-2 w-50 mx-auto" v-for="ticketItem in paymentResult.tickets">

            <div class="ml-lg-5 pl-4 my-0 h4">{{ ticketItem.ticket.label }} #{{ ticketItem.id }}</div>
            <a :href="ticketItem.download_link" class="link h4 text-app m-0 py-3 px-4" target="_blank">Télécharger</a>

        </div>

    </div>
</template>

<script>

export default {
    props: {
        paymentResult: {}
    }
}

</script>

<style>
.ticket-root {
    background-color: #05121e;
    color: white;
    border-radius: 50px;
    padding: 2px;
}

.link {
    color: #05121e;
    overflow: hidden;
    background-color: white;
    border-radius: 50px;
}
</style>