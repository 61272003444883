<template>
    <div>
        <div class="text-center text-xl px-3 md:text-3xl font-light mb-6 mt-2">{{
            $t('app.je_dispose_deja_dun_compte_mon_pass') }}</div>

        <div class="grid md:grid-cols-2 gap-4">

            <div>


                <label for="login" class="bg-slate-100 flex p-1 lg:p-3">
                    <i class="fa-regular fa-user text-xl mr-3 text-gray-400"></i>
                    <input v-model="login"
                        class="input w-full block text-base flex-grow bg-[initial] focus:border-none focus:outline-none border-none active:border-none "
                        :placeholder="$t('app.email_ou_numero_de_telephone')" />
                </label>
                <div class="text-sm text-red-600" v-if="errors.email || errors.login">{{ errors.login || errors.email }}
                </div>


            </div>
            <div>

                <label for="login" class="bg-slate-100 flex p-1 lg:p-3">
                    <i class="fa-solid fa-lock text-xl mr-3 text-gray-400"></i>
                    <input v-model="password" :type="inputType"
                        class="input w-full block text-base flex-grow bg-[initial] focus:border-none focus:outline-none border-none active:border-none "
                        :placeholder="$t('app.mot_de_passe')" />

                    <div v-if="inputType == 'password'" @click="inputType = 'text'"
                        class="transition-all duration-400 hover:text-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                    </div>


                    <div v-if="inputType == 'text'" @click="inputType = 'password'"
                        class="transition-all duration-400 hover:text-primary">

                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                        </svg>

                    </div>

                </label>
                <div class="text-sm text-red-600" v-if="errors.password">{{ errors.password }}</div>

            </div>

        </div>

        <div class="text-right my-2 md:my-0">
            <a class="underline text-sm" href="#">{{ $t('app.mot_de_passe_oublie') }}</a>
        </div>

        <div class="text-center">
            <button @click="doLogin"
                class="bg-primary hover:bg-yellow-600 transition duration-500 text-black p-3 font-light ">{{
                    $t('app.se_connecter') }}
                <span v-if="isLogin" class="inline-block w-[16px] h-[16px]">
                    <svg class="animate-spin w-5 h-15 fill-slate-800" viewBox="3 3 18 18">
                        <path class="opacity-20"
                            d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z">
                        </path>
                        <path
                            d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z">
                        </path>
                    </svg>
                </span></button>
        </div>

        <div class="text-center mt-3">
            <button @click="$emit('goToSignup')"
                class="bg-neutral-500 hover:bg-neutral-700 hover:text-white duration-500 transition p-3 my-2  text-black min-w-[150px] mx-3">{{
                    $t("app.s'inscrire") }}</button>
            <button @click="$emit('goToNoUser')"
                class="bg-neutral-950 hover:bg-neutral-600 transition duration-500 p-3 my-2 font-light text-white min-w-[150px] mx-3">{{
                    $t("app.continuer_en_tant_quinvite") }}</button>
        </div>

    </div>
</template>

<script>

import axios from 'axios';

import { object, string } from 'yup';
let userSchema = object({
    login: string().required('Champ requis'),
    password: string().required('Champ requis'),
});

export default {
    data() {
        return {
            login: '',
            password: '',
            isLogin: false,
            inputType: "password",
            errors: {}
        }
    },
    methods: {
        async doLogin() {

            let fieldValidated = false;
            this.errors = {}

            try {

                const result = userSchema.validateSync({
                    login: this.login,
                    password: this.password,
                }, {
                    abortEarly: false
                });

                fieldValidated = true;

                this.isLogin = true
                const csrfResponse = await axios.get('/sanctum/csrf-cookie');
                console.log(csrfResponse, 'response');
                const response = await axios.post('/login', {
                    email: this.login,
                    password: this.password
                });
                const me = await axios.get('/auth/me');
                this.$emit('userConnected', me.data);
                console.log(me);
            } catch (e) {

                if (fieldValidated == false) {

                    let errors = Object.fromEntries(e.inner.map(
                        item => ([item.path, item.errors.join(', ')])
                    ))
                    console.log('formErrors', errors);
                    this.errors = errors

                    return;
                }

                if (e.response != undefined) {
                    let errors = Object.fromEntries(Object.entries(e.response.data.errors).map(
                        ([key, value]) => ([key, value.join(', ')])
                    ));

                    this.errors = errors;
                }
            } finally {
                this.isLogin = false;
            }
            console.log(response);
        }
    }
}
</script>